import * as React from "react"
import tw from "twin.macro"
import NewLineText from "./new-line-text"

const ContentWrapper = tw.div`
  flex justify-center items-center bg-[#F8F3FD] mb-24
`

const Content = tw.section`
  flex flex-col pt-16 pb-0 relative
`

const TextContent = tw.div`
  flex flex-1 justify-center flex-col text-center mb-8
`
const TextTitle = tw.div`font-bold text-[30px] lg:text-[40px] mb-2 text-center lg:text-center`
const TextSubtitle = tw.div`text-[15px] lg:text-[20px] text-center lg:text-center`
const MediaContent = tw.div`text-center overflow-hidden`
const VideoContent = tw.video``

const CenterContentSection = (props: any) => {
  const mediaStyle = {
    transform: 'translateY(8px)'
  }

  const media = <VideoContent style={mediaStyle} src={props.video} playsInline autoPlay muted loop width="320px" />;

  return (
    <ContentWrapper>
      <Content>
        <TextContent>
          <TextTitle><NewLineText text={props.title} /></TextTitle>
          <TextSubtitle><NewLineText text={props.subtitle} /></TextSubtitle>
        </TextContent>
        <MediaContent className="media-content">
          {media}
        </MediaContent>
      </Content>
    </ContentWrapper>
  )
}

export default CenterContentSection
