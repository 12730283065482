import * as React from "react"
import tw from "twin.macro"
import NewLineText from "./new-line-text"

const JumbotronWrapper = tw.div`
  flex justify-center items-center lg:pt-64 pt-48 mb-96 lg:mb-96
`

const JumbotronContent = tw.div`
  flex flex-1 justify-center items-center flex-col container mx-auto
`;

const AppLinks = tw.div`
  flex flex-1 justify-center items-center flex-col lg:flex-row
`

const JumbotronTitle = tw.h1`
  font-bold text-center mt-4 mb-0 text-[28px] lg:text-[56px] transition-all duration-200 leading-loose text-gray-800
`

const AppLogo = tw.img`mt-6 lg:mt-16 mb-20 lg:mb-32 w-[70px] lg:w-[120px] lg:h-auto`

const AppLinkSpacer = tw.div`w-0 lg:w-[40px] h-3 lg:h-0`

const AppleLinkImage = tw.img`lg:w-[194px] lg:h-[66px]`

const GoogleLinkImage = tw.img`lg:w-[223px] lg:h-[66px]`

const Jumbotron = (props: any) => {
  return (
    <JumbotronWrapper>
      <JumbotronContent>
        <JumbotronTitle>
          <NewLineText text={props.title} key={props.title} />
        </JumbotronTitle>
        <AppLogo src="logo.png" alt="skinlog" />
        <AppLinks>
          <a href="https://apps.apple.com/kr/app/%EC%8A%A4%ED%82%A8%EB%A1%9C%EA%B7%B8-%ED%94%BC%EB%B6%80%EA%B4%80%EB%A6%AC%EC%9D%98-%EC%8B%9C%EC%9E%91/id1527961587?itsct=apps_box_link&itscg=30200">
            <AppleLinkImage
              src="apple_badge.png"
              alt="앱스토어에서 스킨로그 다운로드"
              width={'124px'}
              height={'42px'}
            />
          </a>
          <AppLinkSpacer />
          <a href='https://play.google.com/store/apps/details?id=ai.artlab.candy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <GoogleLinkImage
              alt='다운로드하기 Google Play'
              src='google_badge.png'
              width={'132px'}
              height={'40px'}
            />
          </a>
        </AppLinks>
      </JumbotronContent>
    </JumbotronWrapper>
  )
}

export default Jumbotron
