import * as React from "react"
import { Helmet } from 'react-helmet'

const Head = () => {
  return (
    <Helmet htmlAttributes={{ lang: 'ko' }}>
      <meta charSet="utf-8" />
      <title>스킨로그 - 피부가 좋아지는 작은 습관</title>
      <link rel="canonical" href="https://skinlog.ai" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="description"
        content="당신은 #내피부잘알 인가요? 피부를 알면 당신의 피부도 좋아질 수 있어요. 지금 바로 내 피부를 알아가는 여정을 시작해보세요!"
      />
      <meta property="og:description" content="당신은 #내피부잘알 인가요? 피부를 알면 당신의 피부도 좋아질 수 있어요. 지금 바로 내 피부를 알아가는 여정을 시작해보세요!" />
      <meta property="og:title" content="스킨로그 - 피부가 좋아지는 작은 습관"></meta>
    </Helmet>
  )
}


export default Head
