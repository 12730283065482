import * as React from "react"
import tw from "twin.macro"

const Paragraph = tw.p`
  m-0 leading-tight
`
const NewLineText = (props: any) => {
  if (props.text == undefined) {
    return <></>
  }
  return props.text.split('\n').map((str: string) => <Paragraph key={str}>{str}</Paragraph>)
}

export default NewLineText