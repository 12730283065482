import * as React from "react"
import tw from "twin.macro"

const FooterWrapper = tw.footer`bg-[#F4F4F4] text-xs md:text-base py-12 lg:mt-32 flex w-full justify-center items-center px-0 md:pl-0`
const FooterInner = tw.div`px-8 flex flex-col w-full mx-auto max-w-4xl`
const FooterContentWrapper = tw.div`flex flex-col lg:flex-row`
const FooterContentOuterWrapper = tw.div`flex flex-col`
const FooterContent = tw.div`flex-1 flex flex-col justify-end mb-2 px-4 py-2`
const FooterLogo = tw.img`px-4 mb-3 w-[70px] lg:w-[120px]`
const FooterLink = tw.a`no-underline text-black`
const FooterLinkBold = tw.a`no-underline text-black font-bold`
const Footer = (props: any) => {
  const now = new Date();
  const year = now.getFullYear()

  return (
    <FooterWrapper>
      <FooterInner>
        <FooterLogo src="logo.png" alt="스킨로그 로고" />
        <FooterContentOuterWrapper>
          <FooterContentWrapper>
            <FooterContent>
              <div>(주)아트랩</div>
              <div>서울특별시 광진구 능동로 352-1, 601호</div>
              <div>대표 엄태웅 | 사업자등록번호: 686-87-01309</div>
            </FooterContent>
            <FooterContent>
              <div>
                <FooterLinkBold href="https://branched-durian-9e9.notion.site/cceecd9207da49d7a2f5cc4c74170c7e">개인정보 처리방침</FooterLinkBold> | <FooterLink href="https://branched-durian-9e9.notion.site/302042248a314ac780aa197f21c47a20">이용약관</FooterLink>
              </div>
              <div>Copyright ©{year} ARTLAB. All rights reserved.</div>
            </FooterContent>
          </FooterContentWrapper>
        </FooterContentOuterWrapper>
      </FooterInner>
    </FooterWrapper>
  )
}

export default Footer
