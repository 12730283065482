import * as React from "react"
import tw from "twin.macro"
import NewLineText from "./new-line-text"

const ContentWrapper = tw.div`flex w-full justify-center mb-32 lg:mb-0`
const Content = tw.section`flex-1 flex flex-col-reverse lg:flex-row  justify-start items-center w-full max-w-screen-lg relative`
const TextContent = tw.div`mb-12`
const TextTitle = tw.div`text-[30px] lg:text-[40px] font-bold mb-4 text-center lg:text-left`
const TextSubtitle = tw.div`hidden lg:block text-lg lg:text-[20px] text-center lg:text-left`
const TextMobileSubtitle = tw.div`text-[15px] lg:hidden text-center lg:text-left`
const MediaContent = tw.div`mr-16 pl-16`
const MediaImage = tw.img`w-60 lg:w-80`

const LeftContentSection = (props: any) => {
  let media = <MediaImage src={props.image} alt={props.title} className="drop-shadow" width="310px" />

  return (
    <ContentWrapper className={props.className}>
      <Content>
        <MediaContent className="media-content">
          {media}
        </MediaContent>
        <TextContent className="text-content">
          <TextTitle><NewLineText text={props.title} /></TextTitle>
          <TextSubtitle><NewLineText text={props.subtitle} /></TextSubtitle>
          <TextMobileSubtitle><NewLineText text={props.mobileSubtitle || props.subtitle} /></TextMobileSubtitle>
        </TextContent>
      </Content>
    </ContentWrapper>
  )
}

export default LeftContentSection
