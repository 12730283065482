import * as React from "react"
import tw from "twin.macro"
import CenterContentSection from "../components/center-content-section"
import Footer from "../components/footer"
import Head from "../components/head"
import Jumbotron from '../components/jumbotron'
import LeftContentSection from "../components/left-content-section"
import RightContentSection from "../components/right-content-section"

const Page = tw.main`
  bg-top bg-no-repeat bg-full-width  m-0 p-0 bg-jumbotron-mobile lg:bg-jumbotron transition-all duration-200 overflow-hidden
`
// data
const jumbotron = {
  title: `피부가 좋아지는\n작은습관`,
  subtitle: '피부관리 필수앱, 스킨로그',
}


// markup
const IndexPage = () => {
  return (
    <Page>
      <Head />
      <Jumbotron
        title={`피부가 좋아지는\n작은습관`}
        subtitle={'피부관리 필수앱, 스킨로그'}
      />
      <LeftContentSection
        key='카메라'
        title={'피부 기록은\n관리의 시작'}
        subtitle={'피부 사진을 촬영하고\n나의 피부 상태를\n더 정확하게 확인하세요'}
        mobileSubtitle={'피부 사진을 촬영하고 나의 피부 상태를\n더 정확하게 확인하세요'}
        image='camera.webp'
        className="section camera"
      />
      <RightContentSection
        key='수기기록'
        title='나만의 피부일기'
        subtitle={'오늘의 피부 컨디션을\n손쉽게 기록해보세요'}
        image='record.webp'
        className="section record"
      />
      <LeftContentSection
        key='스킨케어'
        title={'매일하는\n스킨케어 루틴 관리'}
        subtitle={'사용하는 화장품을 등록하고\n스킨케어 루틴을 관리하세요'}
        image='vanity.webp'
        textPadding='9rem'
        className="section skincare"
      />
      <CenterContentSection
        key='트래킹'
        title={'부분별로 세심하게'}
        subtitle={'부분별로 나누어 보여드릴게요\n슬라이드로 세심하게 관찰해보세요'}
        video='crop_tracking.mp4'
      />
      <LeftContentSection
        key='비교'
        title={'한달 전과\n오늘을 비교하기'}
        subtitle={'과거의 피부와 오늘의 피부를\n비교해보세요'}
        image='timeline.webp'
        className="section tracking"
      />
      <RightContentSection
        key='스킨톡'
        title={'고민이 있다면\n스킨톡에'}
        subtitle={'혼자서 해결되지 않는 피부고민을\n스킨톡에서 공유해보세요'}
        image='skintalk.webp'
        className="section skintalk"
      />
      <Footer />
    </Page>
  )
}

export default IndexPage
